import classNames from 'classnames'
import { Ref, forwardRef } from 'react'

type DividerProps = {
  className?: string
}

const Divider = forwardRef(({ className }: DividerProps, ref: Ref<HTMLDivElement>) => {
  return <div ref={ref} className={classNames('w-full bg-gray-50', className ?? 'h-1')}></div>
})

Divider.displayName = 'Divider'

export default Divider
