import { AnimatePresence, motion } from 'framer-motion'
import { ToastControlsType } from '@/hooks/common/useToast'
import classNames from 'classnames'
import Portal from '@/components/common/Portal'
import { ComponentUtils } from '@/utils/design-system/componentUtils'

type ToastProps = {
  className?: string
  liClassName?: string
  toastControls: ToastControlsType
}

const Toast = ({ toastControls, className = 'bottom-100', liClassName }: ToastProps) => {
  const { toastListData } = toastControls
  return (
    <Portal id="toast">
      <section
        className={classNames(
          'absolute mb-[env(safe-area-inset-bottom)] flex h-fit w-screen max-w-body flex-col items-center gap-26',
          className
        )}>
        <AnimatePresence initial={true}>
          {toastListData?.map((toastItem) => {
            return (
              <motion.li
                key={toastItem.id}
                className={ComponentUtils.cn(
                  'body3 flex h-48 w-[calc(100%-3.2rem)] rounded-xs bg-black/80 px-12 py-14 font-medium text-white',
                  liClassName
                )}
                layout
                initial={{ opacity: 0.5, y: 24 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 12 }}
                transition={{ ease: 'easeInOut' }}>
                {toastItem.content}
              </motion.li>
            )
          })}
        </AnimatePresence>
      </section>
    </Portal>
  )
}

export default Toast
